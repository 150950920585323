import React from 'react';

import './Navigation.scss';

function Navigation() {
  return (
    <div className="navigation"></div>
  );
}

export default Navigation;
