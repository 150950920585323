import React from 'react';

import './Hero.scss';

const logo = new URL('../images/ACSB.png', import.meta.url);

function Hero() {
  return (
    <div className="hero">
      <img className="logo" src={logo} alt="Logo" />
      <h1 className="title">AC Speedbot</h1>
      <h2 className="subtitle">More Coming Soon™</h2>
    </div>
  );
}

export default Hero;
