import React from 'react';
import axios from 'axios';
import moment from 'moment';

import './Streams.scss';

function Streams() {
  const [streams, setStreams] = React.useState([]);

  React.useEffect(() => {
    axios.get('/.netlify/functions/latest-streams')
      .then((response) => {
        console.log(response.data);
        setStreams(response.data);
      });
  }, []);

  return (
    <div className="stream-list">
      <h2 className="stream-list-title">10 Latest Streams</h2>
      {streams.map((stream) => (
        <a key={stream.streamId} href={stream.streamURL} rel="noreferrer noopener" target="_blank">
          <div className="stream">
            <img className="stream-image" src={stream.userPicture} alt={stream.title} />
            <div className="stream-info">
              <h3 className="stream-title">{stream.title}</h3>
              <p className="stream-extras">
                <strong>{stream.userName}</strong>
                {' '}
                -
                {' '}
                <span>{moment(stream.startedAt).fromNow()}</span>
              </p>
            </div>
          </div>
        </a>
      ))}
    </div>
  );
}

export default Streams;
