import React from 'react';
import ReactDOM from 'react-dom/client';

import Navigation from './components/Navigation';
import Hero from './components/Hero';
import Streams from './components/Streams';

import './App.scss';

function App() {
  return (
    <>
      <Navigation />
      <Hero />
      <Streams />
    </>
  );
}

ReactDOM.createRoot(document.getElementById('App')).render(<App />);
